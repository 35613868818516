.bimg{
  background-image: url('./b2.png');
  background-position: center;
  background-repeat: no-repeat;
  height: 100%;
  min-height: 100vh;
}

.app{
  font-family: "Graphik Web Regular";
}
/* 
 <div className="  ">

 
 </div>

*/

.border-blue{
  border-color:  #4C52E2;;
}

.border-purple{
  border-color:   #081946;
}
.bg-blue-lenear{
  background: linear-gradient(122.74deg, #1A21BC -28.54%, #166E98 127.06%);
}

.bg-purple-leanier2{
  background: linear-gradient(95.57deg, #1A21BC -37.71%, #166E98 137.71%);
}

.bg-purple{
  background: rgba(26, 33, 188, 0.1);
}
.text-purple{
  color: #1A21BC;
}
.text-d-blue{
  color: #081946;
}
.text-gray{
  color: #3D424F;
}

.dot{
  position: absolute;
width: 18.51px;
height: 18.51px;


background: #FF782D;
transform: rotate(44.42deg);
}

@media screen and (min-width: 768px) {
  .top-50{
    top: 60%;
  }
}



.dot-yellow{
  position: absolute;
width: 26px;
height: 26px;

background: #FFC700;
}

.mouse_scroll {
	display: block;
	margin: 0 auto;
	width: 24px;
	height: 100px;
	margin-top: 125px;
}


.m_scroll_arrows
{
  display: block;
  width: 5px;
  height: 5px;
  -ms-transform: rotate(45deg); /* IE 9 */
  -webkit-transform: rotate(45deg); /* Chrome, Safari, Opera */
  transform: rotate(45deg);
   
  border-right: 2px solid rgb(13, 221, 48);
  border-bottom: 2px solid rgb(13, 221, 48);
  margin: 0 0 3px 4px;
  
  width: 16px;
  height: 16px;
}


.unu
{
  margin-top: 1px;
}

.unu, .doi, .trei
{
    -webkit-animation: mouse-scroll 1s infinite;
    -moz-animation: mouse-scroll 1s infinite;
    animation: mouse-scroll 1s infinite;
  
}

.unu
{
  -webkit-animation-delay: .1s;
  -moz-animation-delay: .1s;
  -webkit-animation-direction: alternate;
  
  animation-direction: alternate;
  animation-delay: alternate;
}

.doi
{
  -webkit-animation-delay: .2s;
  -moz-animation-delay: .2s;
  -webkit-animation-direction: alternate;
  
  animation-delay: .2s;
  animation-direction: alternate;
  
  margin-top: -6px;
}

.trei
{
  -webkit-animation-delay: .3s;
  -moz-animation-delay: .3s;
  -webkit-animation-direction: alternate;
  
  animation-delay: .3s;
  animation-direction: alternate;
  
  
  margin-top: -6px;
}

.mouse {
  height: 42px;
  width: 24px;
  border-radius: 14px;
  transform: none;
  border: 2px solid rgb(13, 221, 48);
  top: 170px;
}

.wheel {
  height: 5px;
  width: 2px;
  display: block;
  margin: 5px auto;
  background: rgb(13, 221, 48);
  position: relative;
  
  height: 4px;
  width: 4px;
  border: 2px solid rgb(13, 221, 48);
  -webkit-border-radius: 8px;
          border-radius: 8px;
}

.wheel {
  -webkit-animation: mouse-wheel 0.6s linear infinite;
  -moz-animation: mouse-wheel 0.6s linear infinite;
  animation: mouse-wheel 0.6s linear infinite;
}

@-webkit-keyframes mouse-wheel{
   0% {
    opacity: 1;
    -webkit-transform: translateY(0);
    -ms-transform: translateY(0);
    transform: translateY(0);
  }

  100% {
    opacity: 0;
    -webkit-transform: translateY(6px);
    -ms-transform: translateY(6px);
    transform: translateY(6px);
  }
}
@-moz-keyframes mouse-wheel {
  0% { top: 1px; }
  25% { top: 2px; }
  50% { top: 3px;}
  75% { top: 2px;}
  100% { top: 1px;}
}
@-o-keyframes mouse-wheel {

   0% { top: 1px; }
  25% { top: 2px; }
  50% { top: 3px;}
  75% { top: 2px;}
  100% { top: 1px;}
}
@keyframes mouse-wheel {

   0% { top: 1px; }
  25% { top: 2px; }
  50% { top: 3px;}
  75% { top: 2px;}
  100% { top: 1px;}
}

@-webkit-keyframes mouse-scroll {

  0%   { opacity: 0;}
  50%  { opacity: .5;}
  100% { opacity: 1;}
}
@-moz-keyframes mouse-scroll {

  0%   { opacity: 0; }
  50%  { opacity: .5; }
  100% { opacity: 1; }
}
@-o-keyframes mouse-scroll {

  0%   { opacity: 0; }
  50%  { opacity: .5; }
  100% { opacity: 1; }
}
@keyframes mouse-scroll {

  0%   { opacity: 0; }
  50%  { opacity: .5; }
  100% { opacity: 1; }
}


